/**
 * An enumeration of the Inclusion or Characteristic images stored on GIA.edu
 *
 * @desc The values are used to construct the URL for the image. Currently the images are stored on GIA.edu in the public dir
 * as follows: https://www.gia.edu//partials/report-check/img/InclusionImages/BlackEllipse.jpg
 *
 * @enum
 */
export enum CharacteristicsInclusions {
    'Bearding' = 'Bearding',
    'Black Ellipse' = 'BlackEllipse',
    'Black Line' = 'BlackLine',
    'Black Line Free' = 'BlackLineFree',
    'Blue Ellipse' = 'BlueEllipse',
    'Blue Text' = 'BlueText',
    'Bruise' = 'Bruise',
    'Cavity' = 'Cavity',
    'Cavity Fill' = 'CavityFill',
    'Chip' = 'Chip',
    'Cleavage' = 'Cleavage',
    'Cloud' = 'Cloud',
    'Cloud Free' = 'CloudFree',
    'Crystal' = 'Crystal',
    'Crystal Free' = 'CrystalFree',
    'Crystal Free Dot' = 'CrystalFreeDot',
    'Etch' = 'Etch',
    'Etch Channel' = 'EtchChannel',
    'Etch Free' = 'EtchFree',
    'Etch Opening' = 'EtchOpening',
    'Extra Facet' = 'ExtraFacet',
    'Feather' = 'Feather',
    'Feather Free' = 'FeatherFree',
    'Grain Center' = 'GrainCenter',
    'ID' = 'ID',
    'In-House Graining' = 'In-HouseGraining',
    'In-House Graining Free' = 'In-HouseGrainingFree',
    'Indented Natural' = 'IndentedNatural',
    'Indented Natural Free' = 'IndentedNaturalFree',
    'Internal Graining' = 'InternalGraining',
    'Internal Graining Free' = 'InternalGrainingFree',
    'Knot' = 'Knot',
    'Knot Free Dot' = 'KnotFreeDot',
    'Laser Drill Hole' = 'LaserDrillHole',
    'Lizard Skin' = 'LizardSkin',
    'Natural' = 'Natural',
    'Natural Free' = 'NaturalFree',
    'Needle' = 'Needle',
    'Pinpoint' = 'Pinpoint',
    'Polish' = 'Polish',
    'Polish Free' = 'PolishFree',
    'Sticky Notes' = 'StickyNotes',
    'Strain Center' = 'StrainCenter',
    'Surface Graining' = 'SurfaceGraining',
    'Surface Graining Free' = 'SurfaceGrainingFree',
    'Twining' = 'Twining',
    'Twining Wisp' = 'TwiningWisp',
    'Twining Wisps' = 'TwiningWisps',
    'Twinning Wisp' = 'TwinningWisp',
}

/**
 * An enumeration of inclusion or characteristic images stored on GIA.edu exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const CharacteristicsInclusion = {
    ...CharacteristicsInclusions,
} as const;

/**
 * A string literal type representing the keys of the `CharacteristicsInclusionImages` enum.
 *
 * @type
 */
export type TCharacteristicsInclusion = keyof typeof CharacteristicsInclusions;
