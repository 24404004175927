import {
    ReportTypeCode,
    TReportTypeCode,
    TGSTProperty,
    TGSTPropertyLabels,
    TLabel,
    TLabelsDictionary,
    TReportData,
} from './types';

/**
 *
 * @param {string} input - The input to validate
 * @returns {boolean}
 */
export function isReportTypeCode(input: string): input is TReportTypeCode {
    return Object.keys(ReportTypeCode).includes(input as TReportTypeCode);
}

export { ReportTypeCode };
export type { TReportTypeCode, TGSTProperty, TGSTPropertyLabels, TLabel, TLabelsDictionary, TReportData };
