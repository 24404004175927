import { CharacteristicsInclusions, CharacteristicsInclusion, TCharacteristicsInclusion } from './types';

/**
 *
 * @param {string} input - The input to validate
 * @returns {boolean}
 */
export function isCharacteristicsInclusionImage(input: string): input is TCharacteristicsInclusion {
    return Object.keys(CharacteristicsInclusions).includes(input as TCharacteristicsInclusion);
}

export { CharacteristicsInclusion, CharacteristicsInclusions };
export type { TCharacteristicsInclusion };
