/**
 * Represents a custom element for displaying notification banners.
 * @extends HTMLElement
 */
class NotificationCarousel extends HTMLElement {
    /**
     * The class constructor.
     */
    constructor() {
        super();
    }

    /**
     * Fetches notifications from the API for a specific site.
     *
     * @param {string} output - The site or "output" identifier for which notifications are requested.
     * @param {string} locale - The locale for which notifications are requested.
     * @returns {Promise<Object>} A Promise that resolves to the retrieved notifications data.
     * @throws {Error} Throws an error if the fetch operation encounters an error.
     */
    async getNotifications(apiHost, output, locale) {
        try {
            // Make a fetch request to the API endpoint for notifications
            const response = await fetch(`https://${apiHost}/api/${output}/${locale}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            // Parse the JSON response
            const data = await response.json();

            // Return the retrieved data
            return data;
        } catch (error) {
            // throw any errors that occur during the fetch operation
            throw error;
        }
    }

    /**
     * Util for creating a new HTMLElement with the specified tagName and properties.
     *
     * @param {string} tagName
     * @param {Object} prop
     * @returns {HTMLElement} - A new HTMLElement with the specified tag and properties.
     */
    elNew = (tagName, prop) => Object.assign(document.createElement(tagName), prop);

    /**
     * Util leveraging a modulo operation to ensure the slide index is within the bounds of the total number of slides.
     *
     * @param {number} n
     * @param {number} m
     * @returns {number}
     */
    mod = (n, m) => ((n % m) + m) % m;

    /**
     * Animation function for the carousel.
     *
     * @param {*} ms
     * @returns {void}
     */
    anim(ms = this.animation) {
        const cMod = this.mod(this.current, this.total);
        this.elCarouselSlider.style.transitionDuration = `${ms}ms`;
        this.elCarouselSlider.style.transform = `translateX(${(-this.current - 1) * 100}%)`;
        this.elSlides.forEach((elSlide, i) => (elSlide.ariaHidden = i === cMod ? false : true));
    }

    /**
     * Sets the current slide index to the previous slide and animates the carousel.
     *
     * @returns {void}
     */
    prev() {
        if (this.current <= -1) return;
        this.current -= 1;
        this.anim();
    }

    /**
     * Sets the current slide index to the next slide and animates the carousel.
     *
     * @returns {void}
     */
    next() {
        if (this.current >= this.total) return;
        this.current += 1;
        this.anim();
    }

    /**
     * Updates the current slide index to the specified index and animates the carousel.
     *
     * @param {number} index
     */
    goto(index) {
        this.current = index;
        this.anim();
    }

    /**
     * Starts the carousel autoplay.
     * @returns {void}
     */
    play() {
        this.interval = setInterval(() => this.next(), this.pause + this.animation);
    }

    /**
     * Stops the carousel autoplay.
     * @returns {void}
     */
    stop() {
        clearInterval(this.interval);
    }

    /**
     * Connected callback function that is called when the custom element is connected to the DOM handling all
     * instantiation such as creating creating the HTML for each notification message based on the data returned via API,
     * creating the carousel controls and assigning event listeners to them, setting up autoplay, the transition between
     * slides, and more.
     *
     * Within the below callback we also set up the "Shadow DOM" for the component and append the carousel to it.
     * @see https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_shadow_DOM
     * @returns {Promise<void>} A promise that resolves when the rendering is complete.
     */
    async connectedCallback() {
        console.debug('[NMS] connectedCallback >> ', true);
        this.arrowSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 64 96" fill="transparent"><path d="M12,8 L52,48 L12,88" stroke="currentColor" stroke-width="16" stroke-linejoin="round" stroke-linecap="round" /></svg>`;
        // this.closeSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="transparent"><path d="M16 2L2 16M2 2L16 16" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>`;
        this.interval = null;
        this.current = 0;
        this.animation = +this.getAttribute('animation') ?? 500;
        this.pause = +this.getAttribute('pause') ?? 5000;
        this.autoplay = this.getAttribute('autoplay') ?? true;
        this.primaryColor = this.getAttribute('primary-color') ?? '#fff';
        this.bgColor = this.getAttribute('background-color') ?? '#000';
        this.readMore = this.getAttribute('read-more') ?? 'Read More';

        this.elCarousel = this.elNew('div', {
            className: 'carousel',
            role: 'region',
        });

        this.elCarouselSlider = this.elNew('div', {
            className: 'carousel-slider',
            role: 'list',
            ariaLive: 'polite',
        });

        this.elNext = this.elNew('button', {
            type: 'button',
            className: 'carousel-arrow carousel-next',
            ariaLabel: 'Next',
            innerHTML: this.arrowSVG,
            onclick: () => this.next(),
        });

        this.elPrev = this.elNew('button', {
            type: 'button',
            className: 'carousel-arrow carousel-prev',
            ariaLabel: 'Previous',
            innerHTML: this.arrowSVG,
            onclick: e => this.prev(),
        });

        try {
            // Get the notifications to render within the banner
            let apiHost = this.getAttribute('data-api-host'),
                output = this.getAttribute('data-output') || 'corporate',
                locale = this.getAttribute('data-locale').replace('_', '-') ?? 'en-US';

            const data = await this.getNotifications(apiHost, output, locale);

            // Provided there are notifications to render
            if (data?.[0]?.content?.[0]?.bodyCopy) {
                // Create the shadow root (closed to prevent access from outside the component)
                const shadow = this.attachShadow({ mode: 'closed' });

                this.total = data.length;

                const style = this.elNew('style', {
                    type: 'text/css',
                    textContent: `
                    :host {
                      --primary-color: ${this.primaryColor};
                      --background-color: ${this.bgColor};
                      --button-padding: 0.75rem;
                      --button-svg-width: 0.8125rem;
                      --arrow-inline-inset: 2.5vw;
                    }

                    *,
                    *::before,
                    *::after {
                      margin: 0;
                      padding: 0;
                      box-sizing: border-box;
                    }

                    .carousel {
                      position: relative;
                      font-family: Arial, Helvetica, sans-serif;
                      color: var(--primary-color);
                      background-color: var(--background-color);
                      overflow: hidden;
                      width: 100%;
                      z-index: 1080;
                      transition: height 400ms;
                    }

                    .carousel-slider {
                      display: flex;
                      transition: 1000ms;
                      z-index: 1090;
                    }

                    .carousel,
                    .carousel-slider {
                      height: 65px;
                    }
                    @media (min-width: 640px) {
                      .carousel,
                      .carousel-slider {
                        height: 48px;
                      }
                    }

                    .carousel-slide {
                      flex: 1 0 100%;
                      display: flex;
                      place-content: center;
                      align-items: center;
                    }

                    .carousel-slide span {
                      max-width: 75vw;
                      text-align: center;
                      line-height: 1.5;
                    }

                    .carousel-slide p,
                    .carousel-slide a {
                      display: inline;
                      color: var(--primary-color);
                      font-size: 0.8125rem;
                      line-height: 1.5;
                    }

                    @media all and (min-width: 992px) {
                      .carousel-slide p,
                      .carousel-slide a {
                        font-size: 1rem;
                      }
                    }

                    .carousel-slide:has(a) p:after {
                      content: '...';
                    }

                    .carousel-slide a {
                      margin-left: 0.5rem;
                    }

                    button {
                      display: inline-flex;
                      color: inherit;
                      cursor: pointer;
                      user-select: none;
                      background-color: transparent;
                      border: 1px solid transparent;
                      padding: var(--button-padding);
                      border-radius: 0.25rem;
                    }

                    .carousel-arrow {
                      display: inline-flex;
                      cursor: pointer;
                      position: absolute;
                      inset: 50% auto auto auto;
                      transform: translateY(-50%);
                      border: none;
                      cursor: pointer;
                    }

                    .carousel-arrow:hover {
                      text-decoration: none;
                    }

                    .carousel-arrow.carousel-prev {
                      transform: scaleX(-1) translateY(-50%);
                      inset-inline-start: var(--arrow-inline-inset);
                    }

                    .carousel-arrow.carousel-next {
                      inset-inline-end: var(--arrow-inline-inset);
                    }

                    svg {
                      width: var(--button-svg-width);
                    }
                    `,
                });

                // Create a 'carousel-slide' to house each notification message (and/or anchor link if provided)
                // TODO: Add anchor link support
                this.elSlides = data.map(item => {
                    const [notification] = item.content;

                    const elSlide = this.elNew('div', {
                        className: 'carousel-slide',
                        role: 'listitem',
                        ariaHidden: true,
                    });

                    const elNotification = this.elNew('span');

                    const elNotificationMessage = this.elNew('p', {
                        textContent: notification.bodyCopy,
                    });
                    elNotification.appendChild(elNotificationMessage);

                    if (notification.url) {
                        const elLink = this.elNew('a', {
                            href: notification.url,
                            textContent: this.readMore,
                        });

                        elNotification.appendChild(elLink);
                    }

                    elSlide.appendChild(elNotification);

                    return elSlide;
                });

                this.elCarouselSlider.append(...this.elSlides);
                this.elCarousel.append(this.elCarouselSlider);

                if (this.total >= 2) {
                    this.elCarousel.append(this.elPrev, this.elNext);
                    this.elCarouselSlider.prepend(this.elSlides[this.total - 1].cloneNode(true));
                    this.elCarouselSlider.append(this.elSlides[0].cloneNode(true));

                    this.elCarouselSlider.addEventListener('transitionend', () => {
                        if (this.current <= -1) this.current = this.total - 1;
                        if (this.current >= this.total) this.current = 0;
                        this.anim(0);
                    });

                    this.anim(0);

                    if (this.autoplay) {
                        this.elCarousel.addEventListener('pointerenter', () => this.stop());
                        this.elCarousel.addEventListener('pointerleave', () => this.play());
                        this.play();
                    }
                }

                shadow.appendChild(style);
                shadow.appendChild(this.elCarousel);
            }
        } catch (error) {
            console.error('error:', error);
        }
    }

    /**
     * Runs when the element is removed from the DOM.
     */
    disconnectedCallback = () => {
        console.info('disconnected', this);
    };
}

export default NotificationCarousel;
