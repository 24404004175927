import React from 'react';

const HeaderSkeleton: React.FC = () => (
    <div className='header-skeleton'>
        <div className='top-bar-skeleton'>
            <div className='skeleton logo-skel'></div>
            <div className='top-misc'>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton top-links-skel'></div>
                <div className='skeleton search-skel'></div>
            </div>
        </div>
        <div className='nav-skeleton'>
            <div className='skeleton' style={{ flex: 3 }}></div>
            <div className='skeleton' style={{ flex: 3 }}></div>
            <div className='skeleton' style={{ flex: 2 }}></div>
            <div className='skeleton' style={{ flex: 2 }}></div>
            <div className='skeleton' style={{ flex: 3 }}></div>
            <div className='skeleton' style={{ flex: 2 }}></div>
        </div>
    </div>
);

export default HeaderSkeleton;
