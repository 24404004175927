import { useQuery } from '@tanstack/react-query';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import React, { useContext } from 'react';
import { Link } from 'react-router';
import HeaderSkeleton from '@@components/Header/HeaderSkeleton';
import { TLocale } from '@@config/locale/';
import { LocaleContext, TLocaleContext } from '@@contexts/LocaleContext';
import { getAWSEnv, getBaseURL, getReportCheckHeaderContentURL } from '@@utils/utils';

const fetchHeaderContent = async (locale: TLocale) => {
    const url: URL = getReportCheckHeaderContentURL(locale);
    const request = new Request(url, {
        method: 'GET',
        headers: {
            Accept: 'text/html',
        },
    });
    const response = await fetch(request);

    if (!response.ok) {
        throw new Error('Failed to fetch header content');
    }

    return response.text();
};

const Header: React.FC = () => {
    const { locale }: TLocaleContext = useContext(LocaleContext);

    const {
        data: content,
        isPending,
        error,
    } = useQuery({
        queryKey: ['headerContent', locale],
        queryFn: () => fetchHeaderContent(locale),
        staleTime: 60 * 60 * 1000, // 60 minutes
    });

    const options: HTMLReactParserOptions = {
        replace(domNode) {
            if (
                (domNode as Element).attribs &&
                (domNode as Element).attribs['href']?.includes('report-check-landing')
            ) {
                return (
                    <Link className='nav-link' to='/report-check-landing' reloadDocument={getAWSEnv() !== 'local'}>
                        Report Check
                    </Link>
                );
            }
            return domNode;
        },
    };

    if (error) {
        console.error(error);
        return <div>Error loading header content</div>;
    }

    if (!isPending) {
        return (
            <>
                <header id='global-header' className='global-header'>
                    {content && (
                        <>
                            {parse(content, options)}
                            <script async src='//cdn.userway.org/widget.js' />
                            <script async src={`${getBaseURL()}/dist/_nav-bundle.js`} />
                        </>
                    )}
                </header>
                <hr className='solid-single' />
            </>
        );
    }

    return <HeaderSkeleton />;
};

export default Header;
