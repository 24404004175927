import { TAssetId } from '@@config/wcs';

/**
 * An enumeration of the standard report type codes available via the RR API.
 *
 * @enum
 */
export enum ReportTypeCodes {
    AL = 'Alexandrite Report',
    ALO = 'Alexandrite Origin Report',
    ALOTR = 'Alexandrite Origin & Traceability Report',
    CCR = "Cat's Eye Chrysoberyl Report",
    CD = 'Colored Diamond Grading Report',
    CDOR = 'Colored Diamond Origin Report',
    CI = 'Colored Diamond Identification and Origin Report',
    CPC = 'Cultured Pearl Classification Report',
    DD = 'Diamond Dossier Report',
    DDO = 'Diamond Dossier with Origin Report',
    DER = 'Diamond eReport, Diamond e-Report',
    DG = 'Diamond Grading Report',
    DOR = 'Diamond Grading with Origin Report',
    EO = 'Emerald Origin Report',
    EOTR = 'Emerald Origin & Traceability Report',
    ER = 'Emerald Report',
    FC = 'Diamond Focus Report',
    ID = 'Identification Report',
    IDTO = 'Identification & Origin Report, Identification Origin Report',
    IDTT = 'Identification Origin & Traceability Report',
    JG = 'Jewelery Grading Report',
    JR = 'Jade Report',
    LGCD = 'Laboratory-Grown Colored Diamond Grading Report, Laboratory-Grown Colored Diamond Report',
    LGCI = 'Laboratory-Grown Colored Diamond Report - Color Identification',
    LGDG = 'Laboratory-Grown Diamond Grading Report, Laboratory-Grown Diamond Report',
    LGDOSS = 'Laboratory-Grown Diamond Report - Dossier',
    MLE = 'Melee Analysis Service',
    PG = 'Pearl Identification and Classification Report',
    PI = 'Pearl Identification Report, Pearl Classification Report',
    RG = 'Ruby Report',
    RO = 'Ruby Origin Report',
    ROTR = 'Ruby Origin & Traceability Report',
    SCD = 'Synthetic Colored Diamond Grading Report',
    SCI = 'Synthetic Diamond Identification Report',
    SDG = 'Synthetic Diamond Grading Report',
    SG = 'Sapphire Report',
    SO = 'Sapphire Origin Report',
    SOTR = 'Sapphire Origin & Traceability Report',
    SPOR = 'Spinel Origin Report',
    SPOTR = 'Spinel Origin & Traceability Report',
    SPR = 'Spinel Report',
    TOR = 'Tourmaline Origin Report',
    TOTR = 'Tourmaline Origin & Traceability Report',
    TR = 'Tourmaline Report',
}

/**
 * An enumeration of report type codes exported as constant readonly strings
 *
 * @const
 * @enum
 */
export const ReportTypeCode = {
    ...ReportTypeCodes,
} as const;

/**
 * A string literal type representing the keys of the `ReportTypeCode` enum.
 *
 * @type
 */
export type TReportTypeCode = keyof typeof ReportTypeCodes;

/**
 * Represents the naming convention for the Oracle WebCenter Sites "GSTProperty"
 * These properties provide simple and single values for i18n strings.
 *
 * @type
 */
export type TGSTProperty = `gia_www_i18n_${string}`;

/**
 * Represents a dictionary object where each key follows the GSTProperty naming convention.
 * The data source for these internationalized properties is the Oracle WebCenter Sites CMS.
 *
 * @type
 */
export type TGSTPropertyLabels = {
    [key: TGSTProperty]: string;
};

/**
 * Represents a label object with three allowed or expected properties: label, text, and heading.
 *
 * @type
 */
export type TLabel = {
    readonly heading?: string;
    readonly text?: string;
    readonly label?: string;
    readonly tooltip?: string;
};

/**
 * Represents a dictionary object of labels and category strings specific to the report results.
 *
 * @type
 */
export type TLabelsDictionary = {
    [key: string]: TLabel;
};

/**
 * Represents an image asset with metadata and display properties.
 *
 * @type
 */
export type TImage = {
    ASSET_NAME: string;
    ASSET_ID: TAssetId;
    SRC: string;
    ALT_TEXT: string;
    CAPTION: string;
};

/**
 * Represents a media gallery with a primary image and collection of gallery images.
 *
 * @type
 */
export type TMedia = {
    ASSET_NAME: string;
    ASSET_ID: TAssetId;
    PRIMARY_IMAGE: TImage;
    GALLERY_IMAGES: TImage[];
    GALLERY_CAPTION: string;
};

/**
 * Represents a content section containing title, media, and body text.
 *
 * @type
 */
export type TContentSection = {
    TITLE: string;
    IMAGE: Record<string, never>;
    MEDIA: TMedia;
    BODY: string[];
};

/**
 * Represents supplemental content containing metadata and structured content sections.
 *
 * @type
 */
export type TSupplementalContent = {
    ASSET_NAME: string;
    ASSET_ID: TAssetId;
    TITLE: string;
    BODY_HTML: string;
    BODY_JSON: TContentSection[];
};

/**
 * Comprehensive type definition for the Report Detail Web Service (RDWB) API response.
 * Contains grading data, supplemental content, and metadata for diamond/gemstone reports.
 * @see {@link https://gialab.atlassian.net/wiki/spaces/API1/pages/2981101882/RDWB+Usage}
 *
 * @type
 */
export type TReportData = {
    REPORT_DT: `${number}/${number}/${number}`;
    REPORT_TYPE_CODE: TReportTypeCode;
    REPORT_TYPE: string;
    REPORT_NO: string;
    ENCRYPTED_REPORT_NO?: string;
    SIMPLE_ENCRYPTED_REPORT_NO?: string;
    ENCRYPTOR_ENCRYPTED_REPORT_NO?: string;
    SHAPE?: string;
    MEASUREMENTS?: string;
    WEIGHT?: string;
    COLOR_GRADE?: string;
    CLARITY_GRADE?: string;
    CUT_GRADE?: string;
    DEPTH?: string;
    TABLE?: string;
    CROWN_ANGLE?: string;
    CROWN_HEIGHT?: string;
    PAVILION_ANGLE?: string;
    PAVILION_DEPTH?: string;
    STAR_LENGTH?: string;
    LOWER_HALF?: string;
    GIRDLE?: string;
    GROWTH_METHOD?: string;
    CULET?: string;
    POLISH?: string;
    SYMMETRY?: string;
    FLUORESCENCE?: string;
    CLARITY_CHARACTERISTICS?: string;
    KTS_IMG?: string;
    INSCRIPTION?: string;
    REPORT_COMMENTS?: string;
    REPORT_SLEEVE_MSG?: string;
    INFO_MSG?: string;
    PDF_URL?: string;
    DTL_PDF_URL?: string;
    DTLP_PDF_UR?: string;
    DTTL_PDF_URL?: string;
    DTLP_PDF_URL?: string;
    PROPIMG?: string;
    DGTIMG?: string;
    ORGPOL?: string;
    ORGROU?: string;
    INSCRIPTION_REFERENCE_ID?: string;
    DIGITAL_CARD?: string;
    TIME_CACHED?: string;
    isGraphQL: 'YES' | 'NO';
    IS_AGS: 'TRUE' | 'FALSE';
    AGS_SHAPE?: string;
    AGS_MEASUREMENTS?: string;
    AGS_CARAT_WEIGHT?: string;
    AGS_LIGHT_PERFORMANCE_GRADE?: string;
    AGS_BRIGHTNESS?: string;
    AGS_FIRE?: string;
    AGS_CONTRAST?: string;
    AGS_PDF_URL?: string;
    AGS_LIGHTIMG?: string;
    DIGITAL_RPT_FLG: 'true' | 'false';
    IS_PDF_AVAILABLE: 'TRUE' | 'FALSE';
    PROVENANCE_NAME?: string;
    SVS_NAME?: string;
    TRACR_ID?: string;
    COR_DATA?: TSupplementalContent[];
    SVS_DATA?: TSupplementalContent[];
    TRACR_DATA?: TSupplementalContent[];
    TREATMENTS_DATA?: TSupplementalContent[];
    GROWTH_METHOD_DATA?: TSupplementalContent[];
    labels: TLabelsDictionary;
};
