import { createContext } from 'react';
import { Locales, TLocale } from '@@config/locale';

export type TLocaleContext = {
    locale: TLocale;
    updateLocale: (locale: TLocale) => void;
};

const defaultContextValue: TLocaleContext = {
    locale: Locales.en_US,
    updateLocale: (): void => {},
};

export const LocaleContext = createContext<TLocaleContext>(defaultContextValue);
