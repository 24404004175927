import React from 'react';
import { TLocale } from '@@config/locale/';
import { getBaseURL } from '@@utils/utils';

type TAppPromoProps = {
    locale: TLocale;
};

const AppPromo: React.FC<TAppPromoProps> = ({ locale }) => {
    const promoImgSrc = `${getBaseURL()}/img/app-promo/${locale}/GIA-App-Promo.jpg`;
    const appStoreImgSrc = `${getBaseURL()}/img/app-promo/${locale}/download-on-the-app-store.jpg`;
    const googlePlayImgSrc = `${getBaseURL()}/img/app-promo/${locale}/get-it-on-google-play.jpg`;

    return (
        <>
            <img className='w-100' src={promoImgSrc} style={{ maxWidth: 400 }} alt='G.I.A. App Promo' />
            {locale === 'en_US' && (
                <div className='d-flex flex-column'>
                    <a
                        href='https://apps.apple.com/us/app/gia-app/id1623642988'
                        style={{ borderBottom: 0 }}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='img-fluid' src={appStoreImgSrc} alt='Download on the App Store' />
                    </a>
                    <a
                        href='https://play.google.com/store/apps/details?id=com.gia.giaapp&hl=en_US&gl=US'
                        style={{ borderBottom: 0 }}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='img-fluid' src={googlePlayImgSrc} alt='Get It on Google Play' />
                    </a>
                </div>
            )}
            {locale === 'ja_JP' && (
                <div className='d-flex flex-column'>
                    <a
                        href='https://apps.apple.com/jp/app/gia-app/id1623642988'
                        style={{ borderBottom: 0 }}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='img-fluid' src={appStoreImgSrc} alt='Download on the App Store' />
                    </a>
                    <a
                        href='https://play.google.com/store/apps/details?id=com.gia.giaapp&hl=ja_JP&gl=JP'
                        style={{ borderBottom: 0 }}
                        target='_blank'
                        rel='noreferrer'
                    >
                        <img className='img-fluid' src={googlePlayImgSrc} alt='Get It on Google Play' />
                    </a>
                </div>
            )}
            {locale === 'zh_CN' && (
                <a
                    href='https://apps.apple.com/cn/app/gia-app/id1623642988'
                    style={{ borderBottom: 0 }}
                    target='_blank'
                    rel='noreferrer'
                >
                    <img className='img-fluid' src={appStoreImgSrc} alt='Download on the App Store' />
                </a>
            )}
        </>
    );
};

export default AppPromo;
