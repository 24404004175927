import React from 'react';

type TTableHeaderProps = {
    title: string;
};

const TableHeader: React.FC<TTableHeaderProps> = ({ title }) => {
    return (
        <thead>
            <tr>
                <th colSpan={2} className='text-uppercase px-0 border-0'>
                    {title}
                </th>
            </tr>
        </thead>
    );
};

export default TableHeader;
