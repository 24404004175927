import React, { useEffect } from 'react';
import { TGSTPropertyLabels, TLabelsDictionary } from '@@config/i18n';
import { TReportTypeCode } from '@@config/reportTypeCode';

type TReportSupplementalsProps = {
    readonly reportTypeCode: TReportTypeCode;
    readonly reportNumber: string | number;
    readonly primaryCTA?: URL | null;
    readonly labels: TLabelsDictionary;
    readonly i18n: TGSTPropertyLabels;
};

const ReportSupplementals: React.FC<TReportSupplementalsProps> = ({
    reportTypeCode,
    reportNumber,
    primaryCTA,
    labels,
    i18n,
}) => {
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
                e.preventDefault();
                if (primaryCTA) {
                    window.open(primaryCTA.toString(), '_blank');
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [primaryCTA]);
    // For the email to friend link curate the email subject and body for the "mailto" href
    const emailMailToHref: string = `mailto:?Subject=${i18n?.['gia_www_i18n_emailsubject']}: ${reportNumber}&body=${i18n?.['gia_www_i18n_emailbody']}: ${window.location.href}`;

    return (
        <div className='report-supplementals d-flex justify-content-end align-items-center'>
            {/* Email Report */}
            <a className='btn btn-light--static' href={emailMailToHref} data-icon='share'>
                <span>{labels?.['SHARE_REPORT']?.label}</span>
            </a>

            {primaryCTA && (
                <a
                    href={primaryCTA.toString()}
                    className='btn btn-primary'
                    data-icon='download'
                    target='_blank'
                    rel='noreferrer'
                >
                    <span>
                        {reportTypeCode === 'DER' ? i18n['gia_www_i18n_view_ereport'] : labels?.['PDF_URL']?.label}
                    </span>
                </a>
            )}
        </div>
    );
};

export type { TReportSupplementalsProps };
export default ReportSupplementals;
